@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes scrollWave {
  from { transform: translateX(0%); }
  to { transform: translateX(-50%); }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.5s ease-out;
}

.svg-container {
  width: 200px;
  height: 160px;
  position: relative;
}

.folder {
  fill: none;
  stroke: #4CAF50;
  stroke-width: 2;
  stroke-linecap: round;
}

.wave {
  fill: none;
  stroke: #4CAF50;
  stroke-width: 1.5;
}

.wave-container {
  animation: scrollWave 3s linear infinite;
  will-change: transform;
}

.wave1 {
  opacity: 0.7;
}

.wave2 {
  opacity: 0.5;
}

.wave3 {
  opacity: 0.3;
}

.loading-text {
  color: #4CAF50;
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: 500;
  text-align: center;
}

.pulsing-text {
  animation: pulse 1.5s ease-in-out infinite;
}

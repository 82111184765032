.brand.logo {
  width: 180px;
  height: 60px;
}

.brand.logo.mobile {
  width: 120px;
}

.brand.logo.enlarged {

  height: 80px;  /* 10% larger than the original 60px */
}

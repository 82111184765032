.connection-quality-bars {
  display: flex;
  align-items: flex-end;
  height: 25px;
  gap: 3px;
  padding: 2px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
}

/* Animation de vérification */
@keyframes pulse {
  0% { transform: scaleY(0.3); opacity: 0.3; }
  50% { transform: scaleY(1); opacity: 1; }
  100% { transform: scaleY(0.3); opacity: 0.3; }
}

.checking-animation .bar {
  animation: pulse 1.2s ease-in-out infinite;
}

.checking-animation .bar:nth-child(1) { animation-delay: 0s; }
.checking-animation .bar:nth-child(2) { animation-delay: 0.2s; }
.checking-animation .bar:nth-child(3) { animation-delay: 0.4s; }
.checking-animation .bar:nth-child(4) { animation-delay: 0.6s; }

.bar {
  width: 6px;
  background-color: rgba(128, 128, 128, 0.2);
  transition: all 0.3s ease;
  border-radius: 2px;
}

/* Animation initiale des barres */
.configuration-phase .bar {
  transform-origin: bottom;
  animation: barFill 0.6s ease-out forwards;
}

@keyframes barFill {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.configuration-phase .bar:nth-child(1) { animation-delay: 0s; }
.configuration-phase .bar:nth-child(2) { animation-delay: 0.2s; }
.configuration-phase .bar:nth-child(3) { animation-delay: 0.4s; }
.configuration-phase .bar:nth-child(4) { animation-delay: 0.6s; }

.bar:nth-child(1) { height: 10px; }
.bar:nth-child(2) { height: 15px; }
.bar:nth-child(3) { height: 20px; }
.bar:nth-child(4) { height: 25px; }

/* Excellent - Vert */
.quality-excellent .bar.active {
  background-color: #10B981;
  box-shadow: 0 0 4px rgba(76, 175, 80, 0.4);
}

/* Good - Bleu */
.quality-good .bar.active {
  background-color: #2196F3;
  box-shadow: 0 0 4px rgba(33, 150, 243, 0.4);
}

/* Medium - Orange */
.quality-medium .bar.active {
  background-color: #FF9800;
  box-shadow: 0 0 4px rgba(255, 152, 0, 0.4);
}

/* Poor - Rouge */
.quality-poor .bar.active {
  background-color: #F44336;
  box-shadow: 0 0 4px rgba(244, 67, 54, 0.4);
}

/* Unknown - Gris */
.quality-unknown .bar.active {
  background-color: #9E9E9E;
  box-shadow: 0 0 4px rgba(158, 158, 158, 0.4);
}

/* Animation de vérification */
.checking-animation .bar {
  transform-origin: bottom;
  animation: pulseBar 1.2s ease-in-out infinite;
}

@keyframes pulseBar {
  0% {
    opacity: 0.3;
    transform: scaleY(0.3);
  }
  50% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0.3;
    transform: scaleY(0.3);
  }
}

.checking-animation .bar:nth-child(1) { animation-delay: 0s; }
.checking-animation .bar:nth-child(2) { animation-delay: 0.2s; }
.checking-animation .bar:nth-child(3) { animation-delay: 0.4s; }
.checking-animation .bar:nth-child(4) { animation-delay: 0.6s; }

/* Style pour l'état de vérification */
.quality-checking .bar {
  background-color: #64748B;
  box-shadow: 0 0 4px rgba(100, 116, 139, 0.4);
}

.fv-wrapper-create-deposit {
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
}

.fv-create-deposit-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.fv-card-create-deposit-header,
.fv-card-create-deposit-footer {
  background: #eee;
}

.uploader-input-wrapper[data-uploadertype="form"] {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px; /* Hauteur minimale pour assurer la visibilité */
}

.uploader-input-wrapper[data-uploadertype="form"] .absolute {
  position: absolute;
  width: 60px;
  height: 190px;
  z-index: 50;
  left: 16px;
  top:-20px;
}

/* Assurer que l'UploaderInput reste visible sur les petits écrans */
@media (max-width: 640px) {
  .uploader-input-wrapper[data-uploadertype="form"] {
    min-height: 120px;
    margin-bottom: 1rem;
  }
  
  .uploader-input-wrapper[data-uploadertype="form"] .absolute {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
  }
}

/* Styles pour assurer que le contenu est centré */
.uploader-input-wrapper[data-uploadertype="form"] .relative {
  position: relative;
  width: 100%;
  z-index: 60;
}

/* Amélioration du responsive pour les différentes tailles d'écran */
@media (max-width: 768px) {
  .fv_layout_current_header {
    flex-direction: column;
    align-items: center;
  }
  
  .fv_layout_current_header_size_wrapper {
    margin-bottom: 0.5rem;
  }
  
  /* Styles pour l'UploaderInput en mode compact */
  .uploader-input-wrapper[data-uploadertype="form"] {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  /* Centrer l'UploaderInput au-dessus du formulaire */
  .uploader-input-wrapper[data-uploadertype="form"] .up-deposit {
    margin: 0 auto;
  }
  
  /* Styles spécifiques pour la phase de configuration */
  .configuration-phase .up-deposit {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    width: auto !important;
    margin: 0 auto 1rem auto !important;
  }
  
  .configuration-phase .up-deposit .group.flex {
    display: inline-flex !important;
    margin: 0 0.5rem !important;
    flex-direction: row !important;
  }
  
  /* Forcer l'affichage horizontal des boutons */
  .configuration-phase .input-upload-buttons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }
  
  .configuration-phase .input-upload-buttons > div {
    display: inline-flex !important;
    flex-direction: row !important;
  }
  
  /* Forcer l'affichage horizontal des boutons */
  .configuration-phase .up-deposit {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 360px !important;
    margin: 0 auto !important;
    padding: 0.5rem !important;
  }
  
  /* Cibler spécifiquement les boutons avec la classe uploader-button */
  .configuration-phase .uploader-button {
    display: inline-flex !important;
    flex-direction: row !important;
    margin: 0 !important;
    flex: 1 1 auto !important;
    justify-content: center !important;
    position: relative !important;
  }
  
  /* Ajuster les dimensions des boutons */
  .configuration-phase .uploader-button .btn,
  .configuration-phase .uploader-button > div {
    width: 48px !important;
    height: 48px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  /* Ajuster les tooltips */
  .configuration-phase .uploader-button .absolute {
    left: 50% !important;
    top: 100% !important;
    transform: translateX(-50%) !important;
    margin-top: 0.5rem !important;
  }
  
  .configuration-phase .uploader-button .group-hover\:scale-x-100 {
    transform-origin: center top !important;
  }
  
  /* Ajuster la position des tooltips dans la phase de configuration */
  .configuration-phase .up-deposit .group.flex .absolute.left-\[calc\(100\%\+4px\)\] {
    left: auto;
    top: 100%;
    margin-top: 0.5rem;
    position: absolute;
    width: auto;
    min-width: 120px;
    text-align: center;
  }
  
  .configuration-phase .up-deposit .group.flex {
    position: relative;
  }
  
  .configuration-phase .up-deposit .group.flex .absolute.left-\[calc\(100\%\+4px\)\] .bg-base-100\/80 {
    transform-origin: top center !important;
  }
}

.uploader-input-wrapper.flex-grow .deposit {
  display: none;
}

.uploader-input-wrapper[data-uploadertype="form"] .bg-base-100\/80.backdrop-blur-sm.shadow-md.rounded-lg.h-12.flex.items-center.whitespace-nowrap.transform.origin-left.scale-x-0.group-hover\:scale-x-100.transition-transform.duration-300.ease-out{
  display: none;
}

/* Ajuster la position du contenu transformé */
.uploader-input-wrapper[data-uploadertype="form"] .transform {
  transform-origin: center;
}

.absolute.left-\[calc\(100\%\+4px\)\].overflow-hidden[data-uploadertype="form"]  {
  display: none;
}

.bg-base-100\/80.backdrop-blur-sm.shadow-md.rounded-lg.h-12.flex.items-center.whitespace-nowrap.transform.origin-left.scale-x-0.group-hover\:scale-x-100.transition-transform.duration-300.ease-out[data-uploadertype="form"] {
  display: none !important;
}

/* Animations pour les transitions de phase */
.phase-transition-enter {
  opacity: 0.5;
  transform: translateY(10px);
}

.phase-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.phase-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.phase-transition-exit-active {
  opacity: 0.5;
  transform: translateY(-10px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

/* Glass morphism effect for modal */
.progress-modal {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  padding: 1.5rem;
}

/* Enhanced progress bar with gradient */
.progress-bar {
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(90deg, #6366f1, #8b5cf6);
  height: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Improved progress text styling */
.progress-text {
  transition: all 0.2s ease-out;
  opacity: 1 !important;
  font-size: 0.875rem;
  color: #374151;
  font-weight: 500;
}

/* Enhanced progress container */
.progress-container {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem;
}

/* Progress bar container with better shadows */
.progress-bar-container {
  height: 0.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  background: rgba(209, 213, 219, 0.3);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Styles pour le panneau de détails des fichiers en responsive */
@media (max-width: 640px) {
  /* Ajuster la largeur du panneau sur mobile */
  div[class*="fixed right-0 top-0 rounded-box bottom-0 mt-20 mb-20 w-96"] {
    width: 85vw !important;
    max-width: 320px;
  }
  
  /* Assurer que le bouton de basculement reste visible */
  .absolute.-left-12.top-1\/2.-translate-y-1\/2 {
    left: -10px !important;
  }
  
  /* Améliorer la lisibilité des noms de fichiers */
  div[class*="group relative border-b border-base-300/20"] h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

/* Smooth hover effect for cancel button */
.cancel-button {
  transition: all 0.2s ease;
  opacity: 0.8;
}

.cancel-button:hover {
  opacity: 1;
  transform: scale(1.05);
}

/* Add smooth transition for the spinner */
.spinner-container {
  transition: transform 0.3s ease-out;
}

/* Dans votre fichier CSS */
progress {
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
  background-color: #f3f4f6;
  border-radius: 0.5rem;
}

progress::-webkit-progress-value {
  background-color: #3b82f6;
  border-radius: 0.5rem;
  transition: width 200ms ease;
}

.group:hover .absolute {
  display: block;
  z-index: 50;
}

.dzu-submitButtonContainer {
  display: none;
}
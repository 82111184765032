/* Étend les styles de base du BurgerMenu */
.fv-burger-menu-wrapper {
  position: fixed;
  height: 100%;
  width: 280px;
  right: -280px;
  top: 0;
  opacity: 0;
  padding-top: 70px;
  transition: right 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.fv-burger-menu-wrapper.opened {
  opacity: 1;
  right: 0;
  transition: right 0.5s, opacity 1s;
}

/* Styles pour le backdrop */
.fv-burger-menu-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 40;
}

.fv-burger-menu-backdrop.close {
  visibility: hidden;
  transition: all 0.4s;
}

.fv-burger-menu-backdrop.open {
  visibility: visible;
  transition: all 0.4s;
  background: rgba(0, 0, 0, 0.15);
}

/* Thème clair */
.fv-burger-menu-wrapper.light {
  background-color: white;
  color: #1a1a1a;
}

/* Thème sombre */
.fv-burger-menu-wrapper:not(.light) {
  background-color: #171717;
  color: white;
}

/* Styles pour les liens et boutons */
.fv-burger-menu-wrapper a,
.fv-burger-menu-wrapper button {
  width: 100%;
  text-align: left;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* Styles pour les icônes */
.fv-burger-menu-wrapper svg {
  flex-shrink: 0;
  transition: transform 0.2s;
}

/* Styles pour les sections */
.fv-burger-menu-wrapper .profile-section,
.fv-burger-menu-wrapper .actions-section {
  padding: 0.5rem;
  width: 100%;
}

/* Styles pour les séparateurs */
.fv-burger-menu-wrapper .border-t {
  border-color: rgba(229, 231, 235, 0.2);
  margin-top: auto;
}

/* Styles pour les conteneurs */
.fv-burger-menu-wrapper .flex-col {
  gap: 0.25rem;
}

/* Responsive */
@media (min-width: 768px) {
  .fv-burger-menu-wrapper {
    width: 320px;
    right: -320px;
  }
}
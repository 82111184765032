

/* Add smooth transitions for progress elements */
.progress-bar {
  transition: width 0.3s ease-out;
}

/* Smooth transition for progress text */
.progress-text {
  transition: all 0.3s ease-out;
}

/* Prevent layout shifts during progress updates */
.progress-container {
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure progress bar container maintains consistent height */
.progress-bar-container {
  height: 0.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
}

/* Add smooth transition for the spinner */
.spinner-container {
  transition: transform 0.3s ease-out;
}

.absolute.z-50.up-deposit {
  left: 35px;
  top: 90px;
}



.mbox .flex-1{
  position:absolute!important;
}

.up-deposit .group.flex:nth-child(3) {
  display: none;
}

/* Styles pour l'affichage horizontal de l'UploaderInput - uniquement pour la phase de configuration */
div[data-phase="CONFIGURATION"] .up-deposit.flex-row {
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
  top: 0;
  left: 0;
  flex-direction: row !important; /* Forcer l'affichage horizontal */
}

/* Ajuster la position des boutons en mode horizontal - uniquement pour la phase de configuration */
div[data-phase="CONFIGURATION"] .up-deposit.flex-row .group.flex {
  margin: 0 0.5rem;
  display: inline-flex !important; /* Forcer l'affichage horizontal */
}

/* Assurer que les tooltips s'affichent correctement en mode horizontal - uniquement pour la phase de configuration */
div[data-phase="CONFIGURATION"] .up-deposit.flex-row .group.flex .absolute.left-\[calc\(100\%\+4px\)\] {
  left: auto;
  top: 100%;
  margin-top: 0.5rem;
  position: absolute;
  width: auto;
  min-width: 120px;
  text-align: center;
}

/* Ajuster la position des tooltips pour qu'ils soient centrés sous les boutons - uniquement pour la phase de configuration */
div[data-phase="CONFIGURATION"] .up-deposit.flex-row .group.flex {
  position: relative;
}

div[data-phase="CONFIGURATION"] .up-deposit.flex-row .group.flex .absolute.left-\[calc\(100\%\+4px\)\] .bg-base-100\/80 {
  transform-origin: top center !important;
}

/* Positionnement au-dessus du formulaire - uniquement pour la phase de configuration */
@media (max-width: 768px) {
  /* Styles spécifiques à la phase de configuration */
  div[data-phase="CONFIGURATION"] .input-upload-buttons {
    width: auto;
    margin: 0 auto 1rem auto;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
  }
  
  /* Forcer l'affichage horizontal des boutons uniquement dans la phase de configuration */
  div[data-phase="CONFIGURATION"] .input-upload-buttons > div {
    display: inline-flex !important;
  }
  
  /* Ajuster la taille des boutons pour qu'ils tiennent bien sur une ligne */
  div[data-phase="CONFIGURATION"] .input-upload-buttons .btn {
    margin: 0 0.25rem;
  }
}
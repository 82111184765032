header.default-header-2 {
  top: 0;
  left: 0;
  z-index: 2000;
}

header.default-header-2 > nav.navbar {
  box-shadow: -21.213px 21.213px 60px 0px rgba(158, 158, 158, 0.3) !important;
}

header.default-header {
  padding: 5px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  box-shadow: -21.213px 21.213px 60px 0px rgba(158, 158, 158, 0.3);
}

header.default-header.normal-header:not(.light),
header.default-header-2.normal-header:not(.light) {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16), 0 0px 10px 0 rgba(0, 0, 0, 0.12);
}

header.default-header:not(.light):not(.normal-header),
header.default-header-2:not(.light):not(.normal-header) {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.03);
}

.fv_header_theme_sombre {
  border-bottom: 0.4px solid rgba(0, 0, 0, 0.4);
}
.fv_header_theme_clair {
  border-bottom: 0.4px solid rgba(255, 255, 255, 0.4);
}

/* Background classes for theme customization */
.bg-custom-0 {
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
}
.bg-custom-1 {
  background: linear-gradient(135deg, #000000 0%, #333333 100%);
}

header.default-header:not(.light) > nav.navbar,
header.default-header-2:not(.light) > nav.navbar {
  box-shadow: none !important;
}

header.default-header.normal-header:not(.light) {
  box-shadow: -21px 21px 60px 0 rgba(198, 198, 198, 0.5);
}

header {
  display: flex;
  height: 60px;
}

header.default-header.light,
header.default-header-2.light {
  z-index: 10;
  top: 0;
  left: 0;

  background-color: #fff;
  box-shadow: -21px 21px 60px 0px rgba(158, 158, 158, 0.3);
}

.slogan {
  line-height: 60px;
}

.slogan:not(.light),
.link:not(.light) {
  font-size: 16px;
  color: #fff;
}

.slogan.light,
.link.light {
  font-size: 12px;
  color: #000;
}

.link:not(.light) {
  text-decoration: underline;
}

.header.separator {
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}

.nav-right * {
  margin: 0 5px;
}

.header-transfer {
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .container {
    width: 100%;
    max-width: none;
  }
}

.fv-burger-menu-btn {
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px;

  position: relative;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.3s ease;
}


.line {
  fill: none;
  stroke: #fff; /* Équivalent à text-white */
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke 300ms ease;
}

.line.light {
  stroke: #ffffff;
}

.fv-burger-menu-btn.opened .line {
  stroke: #ffffff;
}

.fv-burger-menu-btn.opened.light .line {
  stroke: #fff;
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

/* On retire autant que possible le style par défaut */
.timeline {
  -webkit-appearance: none; /* Empêche certains styles implicites */
  appearance: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent; /* on stylise via les pseudo-éléments */
  cursor: pointer;
  outline: none;
}

/* Track pour Chrome/Safari/Opera */
.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;

  /* On veut qu'il occupe 100% de la largeur du conteneur,
     + on enlève 7px de chaque côté pour compenser la moitié du thumb. */
  width: calc(100% + 14px);   /* 14 = largeur du thumb */
  margin-left: -7px;          /* décale la piste vers la gauche */
  margin-right: -7px;         /* décale la piste vers la droite */

  background: #ddd;           /* piste */
  height: 4px;                /* hauteur */
  border-radius: 2px;
  position: relative;
}

/* Le thumb (poignée) */
.timeline::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none;
  width: 14px;
  height: 14px;

  /* Sur une piste de 4px, on le centre avec margin-top négatif */
  margin-top: -5px;

  background: #fff;
  border: 2px solid green;
  border-radius: 50%;
  cursor: pointer;
}

/* Pour Firefox */
.timeline::-moz-range-track {
  width: 100%;
  background: #ddd;
  height: 4px;
  border-radius: 2px;
}

.timeline::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: #fff;
  border: 2px solid green;
  border-radius: 50%;
  cursor: pointer;
}

/* Optionnellement, retirer tout offset implicite pour Firefox */
.timeline::-moz-range-track {
  margin: 0; padding: 0;
}
